<template>
  <div>
    <!-- <ModalLoading ref="modalLoading" :hasClose="false" /> -->
    <b-modal
      v-model="isShowModal"
      no-fade
      hide-footer
      hide-header
      centered
      size="sm"
    >
      <div class="text-center">
        <img
          src="@/assets/images/loading.svg"
          alt="loading"
          class="my-2 loading-icon"
        />
        <div class="textc-primary">กรุณารอสักครู่</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShowModal: true
    };
  },
  async created() {
    await this.$cookies.remove("setTimestamp");
    await this.$cookies.remove("d-order-username");

    this.isShowModal = true;
    // this.$refs.modalLoading.show();
    let body = {
      userId: this.$route.query.userId,
      accesstoken: this.$route.query.accesstoken
    };
    if (this.$route.query.userId) {
      await this.$axios
        .post(
          this.$chatBaseUrl +
            "/user/verify-external/" +
            this.$route.query.userId,
          body
        )
        .then(async response => {
          if (response.data.result === 1) {
            this.$headersChat.Authorization = `Bearer ${response.data.detail.token}`;

            await this.$cookies.set(
              "d-order-session-token",
              response.data.detail.token,
              60 * 60 * 24 * 30
            );
            var timestamp = new Date().getTime();
            await this.$cookies.set("d-order-timestamp", timestamp);
            await this.$store.dispatch("setTimestamp", timestamp);
            this.isShowModal = false;
            if (response.data.detail.isPermission) {
              await this.$store.dispatch("setTempDCRM", {
                brandId: response.data.detail.brandId,
                chatRoomId: response.data.detail.chatroomId
              });
              this.$router.replace({
                path: "/chat",
                query: { brandID: response.data.detail.brandId }
              });
            } else {
              this.$router.replace({
                path: "/401",
                query: { brandID: response.data.detail.brandId }
              });
            }
          } else {
            this.isShowModal = false;
            alert(response.data.message);
            this.$router.replace({
              path: "/401",
              query: { brandID: response.data.detail.brandId }
            });
          }
        })
        .catch(error => {
          alert(error);
          console.log("error", error);
          this.isShowModal = false;
        });
    }
  }
};
</script>

<style></style>
